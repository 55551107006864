import { render } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import OffCanvas from './OffCanvas';

// OffCanvas
document.addEventListener('DOMContentLoaded', () => {
	const allOffCanvas = document.querySelectorAll('.wp-block-ocb-offcanvas');
	allOffCanvas.forEach(offcanvas => {
		const { attributes, content } = JSON.parse(offcanvas.dataset.props);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />
			<OffCanvas attributes={attributes} clientId={attributes.cId}>{content}</OffCanvas>
		</>, offcanvas);

		offcanvas?.removeAttribute('data-props');
	});
});