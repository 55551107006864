const Style = ({ attributes, clientId }) => {
	const { btnAlign, btnTypo, btnColors, popupBG, titleTypo, titleColor } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${btnTypo?.googleFontLink ? `@import url(${btnTypo?.googleFontLink});` : ''}
		${titleTypo?.googleFontLink ? `@import url(${titleTypo?.googleFontLink});` : ''}

		#ocbOffCanvas-${clientId}{
			text-align: ${btnAlign};
		}
		#ocbOffCanvas-${clientId} .ocbButton{
			${btnTypo?.styles || 'font-size: 15px; font-weight: 600;'}
			${btnColors?.styles || 'color: #fff; background: #4527a4;'}
		}
		#ocbOffCanvasPopup-${clientId}{
			${popupBG?.styles ?? 'background-color: #fff;'}
		}
		#ocbOffCanvasPopup-${clientId} .offcanvas-title{
			${titleTypo?.styles || 'font-size: 18px; font-weight: 500;'}
			color: ${titleColor};
		}
	`}} />;
}
export default Style;