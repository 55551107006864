import { useEffect, useState } from '@wordpress/element';
import Offcanvas from 'react-bootstrap/Offcanvas';

const OffCanvas = ({ attributes, clientId, isBackend = false, children }) => {
	const { position, btnText, title, closeBtnColor } = attributes;

	const [show, setShow] = useState(false);

	useEffect(() => {
		const closeBtn = document.querySelector(`#ocbOffCanvasPopup-${clientId} .offcanvas-header .btn-close`);
		if (closeBtn) {
			closeBtn.style.background = `none`;

			closeBtn.innerHTML = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='${closeBtnColor}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>`;
		}
	}, [show]);

	return <>
		<button className='ocbButton' onClick={() => setShow(true)}>{btnText}</button>

		<Offcanvas className='ocbOffCanvasPopup' id={`ocbOffCanvasPopup-${clientId}`} show={show} onHide={() => setShow(false)} placement={position} scroll={true}>
			<Offcanvas.Header closeButton={true}>
				<Offcanvas.Title>{title}</Offcanvas.Title>
			</Offcanvas.Header>

			{isBackend ? <Offcanvas.Body>{children}</Offcanvas.Body> : <Offcanvas.Body dangerouslySetInnerHTML={{ __html: children }} />}
		</Offcanvas>
	</>;
}
export default OffCanvas;